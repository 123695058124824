.loading-hidden {
    display: none;
}

.loading-background {
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: rgba(255,255,255,0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
}

.loading-container{
    background-color: #a22522;
    /* background-color: #6A4BFF; */
    width: 170px;
    height: 170px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}

    .loading-img-logo {
        width: 100px;
    }

    .loading-img-loading {
        margin-top: 10px;
        width: 30px;
        height: 30px;
    }

    /* CARP10 ICON */
    .loading-icon-container {
        position: relative;
        padding: 0px 10px 0px 50px;
    }
        .loading-icon-background {
            background-color: black;
            transform: skew(-20deg);
            width: 100px;
            height: 30px;
            
            top: 0px;
            margin: 0px;
            margin-left: -50px;
        }

        .loading-icon-title {
            font-family: 'Barlow';
            font-weight: 900;
            font-size: 20px;
            color: #f3f3f3;
            line-height: 0px;
            position: absolute;
            top: 0px;
            left: 15px;
            margin: 15px auto 12px;
        }

        .loading-icon-flag {
            transform: skew(-20deg);
            width: 10px;
            position: absolute;
            height: 30px;
            left: 102px;
            top: 0px;
            background:linear-gradient(180deg, red 0, red 33%, yellow 33%, yellow 66%, green 66%, green 100%);
        }
